import { gql } from '../__generated__/gql';

export const UPDATE_PROJECT = gql(/* GraphQL */ `
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      title
      type
      published
      redirectUrl
      thankyouText
      introText
      learningGoal
      askContactInformation
      enableContactInfoCollection
      questions {
        id
        type
        text
        required
        aiInsights
        assets {
          id
          name
          url
          description
        }
        aiConvoDepth
        multipleSelect
        questionOrder
        optionRenderType
        randomize
        options {
          id
          label
          value
          assets {
            id
            name
            url
            description
          }
        }
        tags {
          id
          name
          color
        }
      }
      tags {
        id
        name
        color
      }
    }
  }
`);
