import { faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';
import { useAuth } from '@workos-inc/authkit-react';

import SidebarFooterMenuItem from './manager-sidebar.footer-menu-item';
import { useSidebar, useSidebarData } from './manager-sidebar.hooks';
import SidebarFooterOrganizations from './manager-sidebar.footer-organizations';
import { ManagerOrganizationsCreateOrganization } from '@/manager-organizations';
import { useState } from 'react';

export default function SidebarFooterMenu() {
  const { sidebarState } = useSidebar();
  const { hasValidOrg } = useSidebarData();
  const { user } = useAuth();
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const name: string =
    user?.firstName || user?.lastName
      ? `${user?.firstName ?? ''} ${user.lastName ?? ''}`.trim()
      : user?.email || '';

  return (
    <div className="border-t border-zinc-800 pb-4 pt-2">
      <Root>
        <Trigger
          aria-label="Settings"
          className="group relative flex w-full px-4 py-2 text-left !outline-none !ring-0 transition-all duration-200 focus-visible:ring-offset-0 data-[state=open]:after:absolute data-[state=open]:after:right-0 data-[state=open]:after:top-0 data-[state=open]:after:h-full data-[state=open]:after:w-1 data-[state=open]:after:rounded-l-xl data-[state=open]:after:bg-gradient-to-b data-[state=open]:after:from-rose-300/80 data-[state=open]:after:to-indigo-300/80"
        >
          <div className="flex w-11 justify-center">
            {user?.profilePictureUrl ? (
              <img
                className="h-10 w-10 rounded-full"
                src={user.profilePictureUrl}
                alt="Avatar"
              />
            ) : (
              <div className="font-display flex h-10 w-10 items-center justify-center rounded-full bg-rose-100 text-xl uppercase text-red-700">
                {name
                  .split(' ')
                  .map((n) => n.charAt(0))
                  .join('')}
              </div>
            )}
          </div>

          <div
            data-state={sidebarState}
            className="w-0 overflow-hidden group-hover/sidebar:w-[calc(100%-4.75rem)] data-[state=fixed]:w-[calc(100%-4.75rem)]"
          >
            <p className="ml-2 truncate text-sm font-semibold leading-6 tracking-tight text-white">
              {name}
            </p>
            <p className="ml-2 truncate text-xs font-normal leading-tight tracking-tight text-neutral-400">
              {user?.email}
            </p>
          </div>
        </Trigger>

        <Portal>
          <Content
            side="right"
            className="data-[side=right]:animate-slide-left-and-fade inline-flex max-h-screen min-w-64  flex-col items-center justify-start gap-1 overflow-y-auto rounded-xl bg-white p-1 shadow-2xl shadow-gray-900/70 will-change-[opacity,transform]"
            sideOffset={16}
          >
            {hasValidOrg ? (
              <SidebarFooterOrganizations
                setShowCreateDialog={setShowCreateDialog}
              />
            ) : null}
            <SidebarFooterMenuItem
              icon={faArrowRightFromBracket}
              text="Log out"
              to="/logout"
              className="text-red-700"
            />
          </Content>
        </Portal>
      </Root>

      <ManagerOrganizationsCreateOrganization
        open={showCreateDialog}
        onOpenChange={setShowCreateDialog}
      />
    </div>
  );
}
