import { gql, useMutation, useQuery } from '@apollo/client';
import { useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useToast } from '@/shared-toast';
import {
  AddMessageV2Input,
  Channel,
  GetProjectQuery,
  MessageSender,
  MessageV2,
  OptionRenderType,
  V2_ADD_INSIGHT,
  V2_ADD_MESSAGE,
  V2_GET_INSIGHT,
  V2GetInsightQuery,
} from '@/survey-graphql';
import { fallback } from '@/utilities';
import { z } from 'zod';
import {
  V2_OPTIMISTIC_REPLY_MESSAGE,
  V2_OPTIMISTIC_SENT_MESSAGE,
} from './project-response-chat.helpers';
import { messageFormSchema } from './project-response-form.helpers';
import { getQuestionType, v2UpsertMessage } from './project-response.helpers';

export type MessageFormValues = z.infer<typeof messageFormSchema>;

const channelSchema = z.nativeEnum(Channel).or(fallback(Channel.Link));

export function useResponderEmail() {
  const searchParams = useSearchParams();
  const email = searchParams.get('email');
  const parsedEmail = z.string().email().safeParse(email);
  return parsedEmail.success ? parsedEmail.data : undefined;
}

export function useChannel() {
  const searchParams = useSearchParams();
  const channel = searchParams.get('channel');
  return channelSchema.parse(channel);
}

const NIL_UUID = '00000000-0000-0000-0000-000000000000';
const NIL_UUID_2 = '00000000-0000-0000-0000-000000000001';
const SURVEY_NOT_PUBLISHED_ERROR = 'Survey has expired';

interface UseSurveyResponseProps {
  project: GetProjectQuery['project'];
  responderEmail?: string;
  channel?: Channel;
  sessionId: string | null;
}

export function useProjectResponse({
  project,
  responderEmail,
  channel,
  sessionId,
}: UseSurveyResponseProps) {
  const { addToast } = useToast();
  const [insightId, setInsightId] = useState('');
  const [isPublished, setIsPublished] = useState(project.published);
  const [isSaving, setSaving] = useState(false);
  const [isInsightChecked, setIsInsightChecked] = useState(false);
  const { data: insightData, loading: insightLoading } = useQuery(
    V2_GET_INSIGHT,
    {
      onError: (error) => {
        addToast({ title: error.message, type: 'error' });
      },
      skip: !insightId,
      variables: { id: insightId },
      fetchPolicy: insightId === NIL_UUID ? 'cache-only' : 'cache-first',
    }
  );
  const [addInsight] = useMutation(V2_ADD_INSIGHT);
  const [addMessage, { loading: isSubmitting }] = useMutation(V2_ADD_MESSAGE);

  const initialMessageFromProject: MessageV2 = useMemo(
    () => ({
      id: NIL_UUID,
      content: project.questions[0].text,
      createdAt: new Date().getTime(),
      sender: MessageSender.Assistant,
      optionRenderType: project.questions[0].optionRenderType,
      assets: project.questions[0].assets,
      metadata: {
        type: getQuestionType(project.questions[0].type),
        options:
          project.questions[0]?.options?.map((o) => ({
            value: o.value,
            label: o.label,
            assets: o.assets,
          })) ?? undefined,
        isConversationOver: false,
        required: project.questions[0].required,
        multipleSelect: true,
      },
    }),
    [project.questions]
  );

  const handleCreateInsight = useCallback(
    async (firstQuestionAnswer: string) => {
      setSaving(true);

      await addInsight({
        optimisticResponse: {
          addInsightV2: {
            id: NIL_UUID,
            messages: [
              {
                ...initialMessageFromProject,
                createdAt: new Date().getTime(),
              },
              {
                ...V2_OPTIMISTIC_SENT_MESSAGE,
                content: firstQuestionAnswer,
                createdAt: new Date(Date.now() + 1).getTime(),
              },
              {
                ...V2_OPTIMISTIC_REPLY_MESSAGE,
                createdAt: new Date(Date.now() + 2).getTime(),
              },
            ],
            contact: null,
            progress: 0.1,
          },
        },
        update: (cache, { data }) => {
          if (!data?.addInsightV2) {
            return;
          }
          setInsightId(data?.addInsightV2.id);
          cache.writeQuery({
            query: V2_GET_INSIGHT,
            variables: { id: data.addInsightV2.id },
            data: {
              insightV2: data.addInsightV2,
            },
          });
        },
        onCompleted: ({ addInsightV2 }) => {
          setInsightId(addInsightV2.id);
          setSaving(false);
          window.parent.postMessage({
            type: 'insight-added',
          });
        },
        onError: (error) => {
          if (error.message.includes(SURVEY_NOT_PUBLISHED_ERROR)) {
            setIsPublished(false);
          } else {
            addToast({ title: error.message, type: 'error' });
          }
          setSaving(false);
        },
        variables: {
          input: {
            projectId: project.id,
            email: responderEmail,
            questionId: project.questions[0].id,
            userResponse: firstQuestionAnswer.toString(),
            channel,
            sessionId: sessionId,
          },
        },
      });
    },
    [
      addToast,
      addInsight,
      project.id,
      responderEmail,
      project.questions,
      sessionId,
      initialMessageFromProject,
      channel,
    ]
  );

  const handleAddMessage = useCallback(
    async (input: Pick<AddMessageV2Input, 'content'>) => {
      if (!insightData || insightData.insightV2.id === NIL_UUID) {
        await handleCreateInsight(input.content);
        return;
      } else {
        await addMessage({
          onError: (error) => {
            if (error.message.includes(SURVEY_NOT_PUBLISHED_ERROR)) {
              setIsPublished(true);
            } else {
              addToast({ title: error.message, type: 'error' });
            }
          },
          optimisticResponse: {
            addMessageV2: {
              sent: {
                ...V2_OPTIMISTIC_SENT_MESSAGE,
                content: input.content ?? '',
                createdAt: new Date(Date.now() + 1).getTime(),
              },
              reply: {
                ...V2_OPTIMISTIC_REPLY_MESSAGE,
                createdAt: new Date(Date.now() + 2).getTime(),
              },
              surveyProgress: null,
            },
          },
          update: (cache, { data, errors }) => {
            if (errors) {
              cache.writeQuery({
                query: V2_GET_INSIGHT,
                variables: { id: insightId },
                data: {
                  insightV2: {
                    ...insightData?.insightV2,
                    id: insightData?.insightV2.id,
                    messages: (insightData?.insightV2.messages ?? []).filter(
                      (m) => !m.metadata?.isConversationOver
                    ),
                  },
                },
              });
            }
            if (!data?.addMessageV2) {
              return;
            }
            let messages = insightData?.insightV2?.messages ?? [];
            for (const message of [
              data.addMessageV2.sent,
              data.addMessageV2.reply,
            ]) {
              messages = v2UpsertMessage(messages, message);
            }
            const currentProgress =
              cache.readQuery<V2GetInsightQuery>({
                query: V2_GET_INSIGHT,
                variables: { id: insightId },
              })?.insightV2.progress ?? 0;
            cache.writeFragment({
              id: `InsightV2:${insightId}`,
              data: {
                messages,
                progress:
                  data.addMessageV2.surveyProgress &&
                  data.addMessageV2.surveyProgress > currentProgress
                    ? data.addMessageV2.surveyProgress
                    : currentProgress,
                variables: { id: insightId },
              },
              fragment: gql`
                fragment NewMessages on InsightV2 {
                  messages
                  progress
                }
              `,
            });
          },
          variables: { input: { ...input, insightId } },
        });
      }
    },
    [addMessage, addToast, insightData, insightId, handleCreateInsight]
  );

  useEffect(() => {
    setIsInsightChecked(true);
  }, []);

  const messages = useMemo(() => {
    let insightMessages = insightData?.insightV2.messages ?? [
      initialMessageFromProject,
    ];

    const introText = project.introText;
    if (introText && !insightMessages.some((m) => m.createdAt === 0)) {
      insightMessages = [
        {
          id: NIL_UUID_2,
          content: introText,
          createdAt: 0,
          sender: MessageSender.Assistant,
          optionRenderType: OptionRenderType.Text,
        },
        ...insightMessages,
      ];
    }

    return insightMessages.map((m) => ({
      id: m.id,
      text: m.content,
      createdAt: m.createdAt,
      sender: m.sender,
      type: m.metadata?.type,
      options: m.metadata?.options ?? undefined,
      optionRenderType: m.optionRenderType,
      multipleSelect: true,
      assets: m.assets,
    }));
  }, [insightData, initialMessageFromProject, project.introText]);

  const isConversationOver = useMemo(
    () =>
      !!(
        insightData?.insightV2.messages?.length &&
        insightData?.insightV2.messages[
          insightData.insightV2.messages.length - 1
        ].metadata?.isConversationOver
      ),
    [insightData]
  );

  function handleSubmit(values: MessageFormValues) {
    return handleAddMessage(values);
  }

  function handleConversationOver() {
    if (isConversationOver && project.redirectUrl) {
      window.location.href = project.redirectUrl;
      return Promise.resolve();
    }
  }

  return {
    isPublished,
    isSaving,
    isSubmitting,
    messages: messages.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    ),
    handleSubmit,
    insightLoading: !isInsightChecked || insightLoading,
    isConversationOver: !!(isConversationOver && project.redirectUrl),
    progress: insightData?.insightV2.progress ?? 0,
    handleConversationOver,
  };
}
