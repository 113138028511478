import { OptionRenderType } from '@/manager-graphql';
import { cn } from '@/shared-ui';
import { useState } from 'react';
import { ProjectResponseComponentProps } from './project-response-renderer.type';
import { ProjectResponseAssetItem } from './project-response-asset-item';

interface RatingScaleStarProps {
  options: {
    label: string;
    value: string;
  }[];
  value: string;
  onClick: (value: string) => void;
  readonly?: boolean;
  hideLabels?: boolean;
  className?: string;
  optionRenderType?: OptionRenderType;
  assets?: ProjectResponseComponentProps['assets'];
}

export default function RatingScaleStarSelect({
  options,
  value,
  onClick,
  readonly,
  className,
  hideLabels,
  optionRenderType,
  assets,
}: RatingScaleStarProps) {
  const [hoveredValue, setHoveredValue] = useState<string | null>(null);
  const sortedOptions = options.sort((a, b) => +a.value - +b.value);
  const labels = options?.length
    ? [options[0].label, options[options.length - 1].label]
    : ['', ''];

  const handleMouseEnter = (value: string) => {
    if (!readonly) {
      setHoveredValue(value);
    }
  };

  const handleMouseLeave = () => {
    if (!readonly) {
      setHoveredValue(null);
    }
  };

  const colorMap: Record<string, { border: string; background: string }> = {
    '1': { border: 'border-red-300', background: 'bg-red-50' },
    '2': { border: 'border-orange-300', background: 'bg-orange-50' },
    '3': { border: 'border-yellow-300', background: 'bg-yellow-50' },
    '4': { border: 'border-lime-300', background: 'bg-lime-50' },
    '5': { border: 'border-green-300', background: 'bg-green-50' },
  };

  return (
    <div
      className={cn(
        'ms-5 min-h-20 flex-col items-center justify-start gap-3 rounded-lg border border-gray-200 bg-white p-3 placeholder:flex',
        className
      )}
    >
      {assets && assets?.length > 0 && assets[0]?.url && (
        <ProjectResponseAssetItem asset={assets[0]} />
      )}
      <div
        data-hide-labels={hideLabels}
        className="flex flex-col items-start justify-start gap-1 self-stretch data-[hide-labels=false]:min-h-14"
      >
        <div className="flex flex-wrap items-center justify-center gap-2 self-stretch">
          {sortedOptions.map((v) => {
            const isHovered =
              hoveredValue !== null && +v.value <= +hoveredValue;
            const isSelected = value !== '' && +v.value <= +value;
            return (
              <div
                key={v.value}
                onMouseEnter={() => handleMouseEnter(v.value)}
                onMouseLeave={handleMouseLeave}
                className="w-[calc(20%-8px)]"
              >
                <button
                  onClick={() => onClick(v.value)}
                  data-small={options.length > 5}
                  data-hovered={isHovered}
                  data-selected={isSelected}
                  className={cn(
                    'mx-auto my-auto flex h-10 min-h-10 w-full min-w-16 flex-1 flex-col items-center justify-center gap-2 rounded-lg border border-gray-200 p-2 active:from-rose-100 active:to-indigo-100 active:ring-2 active:ring-sky-300 sm:min-w-[4.9rem]',
                    'data-[selected=false]:bg-gradient-to-r data-[selected=false]:from-rose-50 data-[selected=false]:to-indigo-50',
                    'data-[hovered=false]:hover:bg-gradient-to-r data-[hovered=false]:hover:from-rose-100 data-[hovered=false]:hover:to-indigo-100',
                    isHovered && [
                      colorMap[v.value]?.background,
                    ],
                    isSelected && [
                      colorMap[value]?.border,
                      colorMap[value]?.background,
                    ]
                  )}
                  disabled={readonly}
                >
                  <div
                    data-optionRenderType={optionRenderType}
                    data-hovered={isHovered}
                    data-selected={isSelected}
                    className={
                      'text-center font-sans text-gray-900 transition-all'
                    }
                  >
                    {isHovered || isSelected ? (
                      '⭐'
                    ) : (
                      <img
                        src="/img/star-outlined.svg"
                        className="h-6 w-6 transition-all"
                        alt="star-outlined"
                      />
                    )}
                  </div>
                </button>
              </div>
            );
          })}
        </div>
        {!hideLabels && (
          <div className="flex items-start justify-between self-stretch">
            {labels.map((label, index) => (
              <div
                key={index}
                className="text-center font-sans text-xs text-gray-500"
              >
                {label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
