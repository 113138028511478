import { gql } from '../__generated__/gql';

export const GET_PROJECT = gql(/* GraphQL */ `
  query getProject($id: UUID!) {
    project(id: $id) {
      id
      published
      redirectUrl
      introText
      title
      type
      questions {
        aiConvoDepth
        aiInsights
        assets {
          id
          name
          url
          description
          createdAt
          updatedAt
        }
        id
        multipleSelect
        optionRenderType
        options {
          id
          label
          value
          assets {
            id
            name
            url
            description
            createdAt
            updatedAt
          }
        }
        randomize
        required
        text
        type
      }
    }

    projectOrgSettings(projectId: $id) {
      avatarUrl
      logoUrl
      brandColor
      hideTheysaidLogo
    }
  }
`);
