import { faSpinner, faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import Markdown from 'react-markdown';
import rehypeExtLinks from 'rehype-external-links';
import z from 'zod';

import { OptionRenderType } from '@/survey-graphql';
import { fallback } from '@/utilities';
import { ensureTripleBackticks } from './ui.chat.helpers';
import { useScrollIntoView } from './ui.chat.hooks';
import { cn } from './ui.helpers';

const messageSchema = z.object({
  createdAt: z.union([
    z.date(),
    z
      .string()
      .datetime()
      .transform((d) => new Date(d)),
    z.number().transform((n) => new Date(n)),
  ]),
  id: z.string(),
  sender: z.enum(['assistant', 'system', 'user']),
  text: z.string(),
  type: z.string().optional(),
  optionRenderType: z
    .nativeEnum(OptionRenderType)
    .or(fallback(OptionRenderType.Text)),
  options: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
        assets: z
          .array(
            z.object({
              id: z.string(),
              name: z.string().nullable().optional(),
              url: z.string().url(),
              description: z.string().nullable().optional(),
              createdAt: z.string().optional(),
              updatedAt: z.string().optional(),
            })
          )
          .nullish(),
      })
    )
    .optional(),
  multipleSelect: z.boolean().optional(),
  assets: z
    .array(
      z.object({
        id: z.string(),
        name: z.string().nullable().optional(),
        url: z.string().url(),
        description: z.string().nullable().optional(),
      })
    )
    .nullish(),
});
const messagesSchema = z.array(messageSchema);

type Messages = z.infer<typeof messagesSchema>;

interface ChatProps {
  loading?: boolean;
  messages: Messages;
  showDateTime?: boolean;
  onLoadMore?: () => void;
  optimisticReplyMessageId?: string;
  optimisticSentMessageId?: string;
  renderAssistantMessage?: (
    message: Messages[number],
    idx: number,
    arr: Messages
  ) => React.ReactNode;
  avatarUrl?: string | null;
  scrollToEnd?: boolean;
  formatUserMessage?: (message: Messages[number]) => string;
  className?: string;
  focusedMessageId?: string;
}

export default function Chat({
  messages,
  showDateTime = false,
  onLoadMore,
  optimisticReplyMessageId,
  optimisticSentMessageId,
  renderAssistantMessage,
  avatarUrl,
  scrollToEnd = true,
  formatUserMessage,
  loading,
  className,
  focusedMessageId,
}: ChatProps) {
  const parsedMessages = messagesSchema.parse(messages);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useScrollIntoView(messages, scrollContainerRef);
  return (
    <div
      ref={scrollContainerRef}
      className={cn(
        'flex h-full flex-col-reverse gap-4 overflow-y-auto overflow-x-hidden p-4',
        className
      )}
    >
      {onLoadMore && !loading ? (
        <InView as="div" onChange={onLoadMore} className="mx-auto mt-4">
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        </InView>
      ) : null}
      <div ref={scrollToEnd ? scrollRef : undefined} />
      {loading ? (
        <div className="flex h-full w-full items-center justify-center">
          <FontAwesomeIcon
            icon={faSpinnerThird}
            className="h-8 w-8 animate-spin text-gray-900"
          />
        </div>
      ) : (
        [...parsedMessages].reverse().map((message, index, arr) => {
          const isDifferentDateFromPrevious =
            index === 0 ||
            parsedMessages[index - 1].createdAt.toDateString() !==
              message.createdAt.toDateString();
          let date = '';
          if (isDifferentDateFromPrevious) {
            const today = new Date();
            const isToday =
              message.createdAt.toDateString() === today.toDateString();
            const isYesterday =
              message.createdAt.toDateString() ===
              new Date(today.setDate(today.getDate() - 1)).toDateString();
            date = isToday
              ? 'Today'
              : isYesterday
              ? 'Yesterday'
              : message.createdAt.toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year:
                    message.createdAt.getFullYear() === today.getFullYear()
                      ? undefined
                      : 'numeric',
                });
          }
          const isOptimisticReplyMessage =
            optimisticReplyMessageId && message.id === optimisticReplyMessageId;
          const isOptimisticSentMessage =
            optimisticSentMessageId && message.id === optimisticSentMessageId;

          return (
            <Fragment key={message.id}>
              {showDateTime && isDifferentDateFromPrevious ? (
                <div className="px-3 pb-3 text-center text-xs font-medium leading-normal text-zinc-500">
                  {date}
                </div>
              ) : null}

              <div
                data-message={message.id}
                data-sender={message.sender}
                data-focused={message.id === focusedMessageId}
                data-new={index === arr.length - 1}
                className={`group ${message.sender} data-[sender=user]:animate-slide-in-from-right data-[sender=assistant]:animate-slide-in-from-left data-[new=false]:!animation-none max-w-full data-[new=true]:mb-2 data-[sender=assistant]:mr-auto data-[sender=user]:ml-auto`}
              >
                <div className="group-[.user]:text-right">
                  <div className="relative">
                    {message.sender === 'assistant' ? (
                      <div
                        data-optimistic={isOptimisticReplyMessage}
                        className="absolute -top-4 flex flex-col items-center gap-1 data-[optimistic=true]:-top-8"
                      >
                        <img
                          alt="Illustration of a stylized owl with large yellow eyes and gray feathers, perched on a red branch with a serious expression"
                          className="h-9 w-9 rounded-full border border-zinc-200 bg-white"
                          src={
                            avatarUrl ??
                            'https://static.theysaid.io/images/evo.png'
                          }
                        />
                        {isOptimisticReplyMessage ? (
                          <div className="w-12 scale-50 ps-6 opacity-85 grayscale">
                            <div className="loader before:bg-zinc-800 after:bg-zinc-800"></div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {!isOptimisticReplyMessage ? (
                      message.sender === 'assistant' &&
                      renderAssistantMessage ? (
                        renderAssistantMessage(message, index, arr)
                      ) : (
                        <div
                          data-sender={message.sender}
                          data-optimistic={isOptimisticSentMessage}
                          className="prose animate-slide-in-from-bottom float-right w-fit break-words rounded-xl border border-zinc-200 bg-white p-3 text-left text-sm leading-tight text-gray-600 transition-opacity group-[.user]:bg-gray-200 data-[sender=assistant]:pt-5 data-[optimistic=true]:opacity-90 group-data-[focused=true]:ring-1 group-data-[focused=true]:ring-sky-500"
                        >
                          <Markdown
                            rehypePlugins={[
                              [rehypeExtLinks, { target: '_blank' }],
                            ]}
                          >
                            {ensureTripleBackticks(
                              formatUserMessage?.(message) ?? message.text
                            )}
                          </Markdown>
                        </div>
                      )
                    ) : null}

                    {showDateTime ? (
                      <span className="mx-3 text-center text-xs font-medium lowercase leading-tight text-neutral-600">
                        {message.createdAt.toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })
      )}
    </div>
  );
}
