import { faClose} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@/shared-ui';
import { useStripeProducts, useUpgrade } from './manager-upgrade.hooks';
import { ManagerPricingItem } from './manager-pricing-item';
import { formatStripePrice } from './manager-upgrade.helpers';
import { STRIPE_PRODUCT_CODES } from './manager-upgrade.const';

interface UpgradeGatedDialogProps {
  children?: React.ReactNode;
}

export const UpgradeGatedDialog = ({ children }: UpgradeGatedDialogProps) => {
  const {
    showPremiumFeatureDialog,
    handleTogglePremiumFeature,
    handleUpgrade,
    isUpgradeLoading,
  } = useUpgrade();
  const stripeProducts = useStripeProducts(STRIPE_PRODUCT_CODES.PRO);
  const proProduct = stripeProducts?.find(
    (product) => product.metadata?.productCode === STRIPE_PRODUCT_CODES.PRO
  );

  return (
    <Dialog
      open={showPremiumFeatureDialog}
      onOpenChange={handleTogglePremiumFeature}
    >
      {children}
      <DialogContent className="max-h-[90vh] w-[90vw] max-w-5xl py-8">
        <DialogTitle className="px-12 pb-6">
          <p className="mb-2 text-sm text-gray-400">
            This feature isn't available on your current plan
          </p>
          <p className="font-display text-3xl font-semibold leading-tight">
            Upgrade to{' '}
            <span className="bg-gradient-to-r from-[#FB7185] to-[#818CF8] bg-clip-text text-transparent transition-colors">
              Pro
            </span>{' '}
            to unlock branding
          </p>
        </DialogTitle>
        <DialogClose asChild>
          <div className="absolute right-2 top-4">
            <Button variant="ghost">
              <FontAwesomeIcon icon={faClose} />
            </Button>
          </div>
        </DialogClose>
        <div className="flex justify-center gap-4 overflow-y-auto rounded-b-md">
          {proProduct && (
            <ManagerPricingItem
              title="Pro"
              description="Advanced features for experts"
              price={{
                amount: formatStripePrice(proProduct?.prices[0].amount),
                interval: proProduct?.prices[0].interval ?? 'month',
              }}
              features={[
                'Branding - your logo, your \n colors, your avatar',
                '3 active AI surveys',
                'Unlimited responses',
                'Unlimited seat licenses',
                'AI Interviews',
              ]}
              buttonText="Upgrade"
              buttonOnClick={() => handleUpgrade(proProduct?.prices[0]?.id)}
              isLoading={isUpgradeLoading}
            />
          )}
          <img src="/img/branding.svg" alt="Arc" />
        </div>
      </DialogContent>
    </Dialog>
  );
};