import { OptionRenderType } from '@/survey-graphql';
import ProjectResponseImageChoice from './project-response-image-choice';
import { ProjectResponseOpenEndRendererComponent } from './project-response-renderer-open-end';
import { ProjectResponseComponentProps } from './project-response-renderer.type';
import ProjectResponseSelect from './project-response-select';

export type SelectPayload = {
  options: {
    label: string;
    value: string;
    assets?: {
      id: string;
      name: string;
      url: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    }[];
  }[];
};

export function ProjectResponseMultiSelectRendererComponent({
  text,
  options = [],
  value,
  onChange,
  readonly,
  optionRenderType,
  assets,
}: ProjectResponseComponentProps) {
  return (
    <div className="flex flex-col gap-2">
      <ProjectResponseOpenEndRendererComponent text={text} assets={assets} />
      {optionRenderType === OptionRenderType.Media ? (
        <ProjectResponseImageChoice
          options={options}
          value={value ?? ''}
          onClick={(value) => onChange?.(value)}
          readonly={readonly}
          multiselect={true}
          assets={assets}
        />
      ) : (
        <ProjectResponseSelect
          onClick={(value) => onChange?.(value)}
          options={options}
          value={value ?? ''}
          readonly={readonly}
          multiselect={true}
          assets={assets}
        />
      )}
    </div>
  );
}
