import { useMutation, useQuery } from '@apollo/client';
import { endOfMonth, startOfMonth } from 'date-fns';
import { atom, useRecoilState } from 'recoil';
import { useCallback } from 'react';

import {
  BillingPlanCode,
  CREATE_CHECKOUT_SESSION,
  CREATE_CUSTOMER_PORTAL_SESSION,
  GET_STRIPE_PRODUCTS,
  GET_UPGRADE_DATA,
} from '@/manager-graphql';

const currentDate = new Date();
const since = startOfMonth(currentDate).toISOString();
const until = endOfMonth(currentDate).toISOString();

const upgradeState = atom({
  key: 'upgradeState',
  default: {
    showUpgrade: false,
  },
});

export enum PremiumFeature {
  None = 'none',
  Branding = 'branding',
}

const premiumFeatureDialogState = atom<{
  feature: PremiumFeature;
}>({
  key: 'premiumFeatureDialogState',
  default: {
    feature: PremiumFeature.None,
  },
});

const useCreateCustomerPortalSession = () =>
  useMutation(CREATE_CUSTOMER_PORTAL_SESSION);

export const useStripeProducts = (productCode?: string) => {
  const { data } = useQuery(GET_STRIPE_PRODUCTS, {
    variables: {
      filter: {
        name: productCode ?? undefined,
      },
    },
  });
  return data?.stripeProducts?.products;
};

export const useUpgradeBilling = () => {
  const [createCustomerPortalSession] = useCreateCustomerPortalSession();
  const [createCheckoutSession, { loading: checkoutSessionLoading }] =
    useMutation(CREATE_CHECKOUT_SESSION);
  const handleCustomerPortalSession = async () => {
    const response = await createCustomerPortalSession();
    if (response?.data?.createCustomerPortalSession?.url) {
      window.location.replace(response?.data?.createCustomerPortalSession?.url);
    }
  };
  const handleCheckoutSession = async (priceId: string) => {
    const response = await createCheckoutSession({ variables: { priceId } });
    if (response?.data?.createCheckoutSession?.url) {
      window.location.replace(response?.data?.createCheckoutSession?.url);
    }
  };
  return {
    handleCustomerPortalSession,
    handleCheckoutSession,
    checkoutSessionLoading,
  };
};

export const useUpgrade = () => {
  const { data, loading } = useQuery(GET_UPGRADE_DATA, {
    variables: {
      insightsCountInput: {
        since,
        until,
      },
    },
  });
  const [state, setState] = useRecoilState(upgradeState);
  const [premiumFeatureState, setPremiumFeatureState] = useRecoilState(
    premiumFeatureDialogState
  );
  const {
    handleCustomerPortalSession,
    handleCheckoutSession,
    checkoutSessionLoading,
  } = useUpgradeBilling();

  const planDetails = data?.companyInfo?.planDetails;
  const totalAllowedInsights = planDetails?.totalAllowedInsights ?? 0;
  const totalInsights = data?.companyInfo?.insightsCount ?? 0;
  const remainingInsights = Math.max(totalAllowedInsights - totalInsights, 0);
  const hasReachedLimit =
    totalInsights && totalInsights >= totalAllowedInsights;

  const handleToggleUpgrade = () => {
    if (planDetails?.planCode !== BillingPlanCode.Free) {
      handleCustomerPortalSession();
      return;
    }

    setState({
      showUpgrade: !state?.showUpgrade,
    });
  };

  const handleTogglePremiumFeature = useCallback(
    (featureOrOpen: PremiumFeature | boolean) => {
      if (typeof featureOrOpen === 'boolean') {
        setPremiumFeatureState({
          feature: featureOrOpen
            ? premiumFeatureState.feature
            : PremiumFeature.None,
        });
      } else {
        setPremiumFeatureState({
          feature: featureOrOpen,
        });
      }
    },
    [premiumFeatureState.feature, setPremiumFeatureState]
  );

  const handleUpgrade = (priceId: string | undefined) => {
    if (priceId) {
      handleCheckoutSession(priceId);
    }
  };

  return {
    totalInsights,
    planDetails,
    stripeCustomerId: data?.companyInfo?.stripeCustomerId,
    remainingInsights,
    hasReachedLimit,
    showUpgrade: state?.showUpgrade,
    handleToggleUpgrade,
    loading,
    showPremiumFeatureDialog:
      premiumFeatureState.feature !== PremiumFeature.None,
    premiumFeature: premiumFeatureState.feature,
    handleTogglePremiumFeature,
    handleUpgrade,
    isUpgradeLoading: checkoutSessionLoading,
  };
};
