import { AuthKitProvider } from '@workos-inc/authkit-react';
import { useNavigate } from 'react-router-dom';

type Props = Omit<
  React.ComponentProps<typeof AuthKitProvider>,
  'devMode' | 'onRedirectCallback'
>;

export default function AuthKitProviderWithRedirect(props: Props) {
  const navigate = useNavigate();

  const handleRedirectCallback = (redirectParams: {
    state: Record<string, unknown> | null;
  }) => {
    sessionStorage.clear();
    navigate(redirectParams.state?.returnTo || 'home');
  };

  return (
    <AuthKitProvider
      {...props}
      // devMode is set to true to use local storage
      devMode
      onRedirectCallback={handleRedirectCallback}
    />
  );
}
