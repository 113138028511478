import {
  faCreditCard,
  faKey,
  faPalette,
  faUserGear,
  faGear,
} from '@fortawesome/pro-light-svg-icons';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';

import { BillingPlanCode } from '@/manager-graphql';
import { PremiumFeature, useUpgrade, useUpgradeBilling } from '@/manager-upgrade';
import SidebarFooterMenuButtonItem from './manager-sidebar.footer-menu-button-item';
import SidebarFooterMenuItem from './manager-sidebar.footer-menu-item';
import { useSidebar, useSidebarData } from './manager-sidebar.hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

export default function SidebarFooterSettingsMenuItem() {
  const navigate = useNavigate();
  const { sidebarState } = useSidebar();
  const { hasValidOrg } = useSidebarData();
  const { handleToggleUpgrade, planDetails, stripeCustomerId, handleTogglePremiumFeature } = useUpgrade();
  const { handleCustomerPortalSession } = useUpgradeBilling();

  const handlePlanAndBilling = () => {
    if (
      planDetails?.planCode === BillingPlanCode.Free &&
      !stripeCustomerId?.trim()?.length
    ) {
      handleToggleUpgrade();
      return;
    } else {
      handleCustomerPortalSession();
    }
  };

  const handleBranding = () => {
    if (planDetails?.planCode === BillingPlanCode.Free) {
      handleTogglePremiumFeature(PremiumFeature.Branding);
      return;
    } else {
      navigate('/branding');
    }
  };

  return (
    <div className="pb-2">
      <Root>
        <Trigger
          aria-label="Settings"
          className="group relative flex w-full p-4 text-left !outline-none !ring-0 transition-all duration-200 hover:bg-gradient-to-r hover:from-rose-300/10 hover:to-indigo-300/10  focus-visible:ring-offset-0 data-[state=open]:bg-gradient-to-r data-[state=open]:from-rose-300/15 data-[state=open]:to-indigo-300/15 data-[state=open]:after:absolute data-[state=open]:after:right-0 data-[state=open]:after:top-0 data-[state=open]:after:h-full data-[state=open]:after:w-1 data-[state=open]:after:rounded-l-xl data-[state=open]:after:bg-gradient-to-b data-[state=open]:after:from-rose-300/80 data-[state=open]:after:to-indigo-300/80"
        >
          <div className="flex w-11 justify-center">
            <FontAwesomeIcon className="text-white" icon={faGear} size="xl" />
          </div>

          <div
            data-state={sidebarState}
            className="w-0 overflow-hidden  group-hover/sidebar:w-[calc(100%-4.75rem)]  data-[state=fixed]:w-[calc(100%-4.75rem)]"
          >
            <p className="ml-2 truncate text-sm font-semibold leading-6 tracking-tight text-white">
              Settings
            </p>
          </div>
        </Trigger>

        <Portal>
          <Content
            side="right"
            className="data-[side=right]:animate-slide-left-and-fade inline-flex min-w-64 flex-col items-center justify-start gap-1 rounded-xl bg-white p-1 shadow-2xl shadow-gray-900/70 will-change-[opacity,transform]"
            sideOffset={16}
          >
            {hasValidOrg ? (
              <>
                <SidebarFooterMenuItem
                  icon={faUserGear}
                  text="User Management"
                  to="/user-management"
                />
                <SidebarFooterMenuButtonItem
                  icon={faCreditCard}
                  text="Plans & Billing"
                  onClick={handlePlanAndBilling}
                />
                <SidebarFooterMenuButtonItem
                  icon={faPalette}
                  text="Branding"
                  onClick={handleBranding}
                />
                <SidebarFooterMenuItem
                  icon={faKey}
                  text="API Access"
                  to="/api-access"
                />
              </>
            ) : null}
          </Content>
        </Portal>
      </Root>
    </div>
  );
}
