import { OptionRenderType } from '@/manager-graphql';
import { cn } from '@/shared-ui';
import { renderValue } from './project-response-rating-scale-select.helpers';
import RatingScaleStarSelect from './project-response-rating-scale-star-select';
import { ProjectResponseAssetItem } from './project-response-asset-item';
import { ProjectResponseComponentProps } from './project-response-renderer.type';

interface RatingScaleProps {
  options: {
    label: string;
    value: string;
  }[];
  value: string;
  onClick: (value: string) => void;
  readonly?: boolean;
  hideLabels?: boolean;
  className?: string;
  optionRenderType?: OptionRenderType;
  assets?: ProjectResponseComponentProps['assets'];
}

export default function RatingScaleSelect({
  options,
  value,
  onClick,
  readonly,
  className,
  hideLabels,
  optionRenderType,
  assets,
}: RatingScaleProps) {
  const sortedOptions = options.sort((a, b) => +a.value - +b.value);
  const labels = options?.length
    ? [options[0].label, options[options.length - 1].label]
    : ['', ''];

  if (optionRenderType === OptionRenderType.Star) {
    return (
      <RatingScaleStarSelect
        options={options}
        value={value}
        onClick={onClick}
        readonly={readonly}
        className={className}
        hideLabels={hideLabels}
        optionRenderType={optionRenderType}
        assets={assets}
      />
    );
  }

  return (
    <div
      className={cn(
        'ms-5 min-h-20 flex-col items-center justify-start gap-3 rounded-lg border border-gray-200 bg-white p-3 placeholder:flex',
        className
      )}
    >
      {assets && assets?.length > 0 && assets[0]?.url && (
        <ProjectResponseAssetItem asset={assets[0]} />
      )}
      <div
        data-hide-labels={hideLabels}
        className="flex flex-col items-start justify-start gap-1 self-stretch data-[hide-labels=false]:min-h-14"
      >
        <div className="flex flex-wrap items-center justify-center gap-2 self-stretch">
          {sortedOptions.map((v) => (
            <div
              data-selected={value === v.value}
              data-optionRenderType={optionRenderType}
              data-small={options.length > 5}
              className="flex h-10 min-h-10 w-16 flex-1 items-center justify-center rounded-[9px] bg-gradient-to-r from-rose-100 to-indigo-100 hover:from-rose-200 hover:to-indigo-200 active:from-rose-400 active:to-indigo-400 data-[optionRenderType=Emoji]:h-12 data-[optionRenderType=Emoji]:min-h-12 data-[small=true]:w-10 data-[small=true]:max-w-10 data-[selected=true]:from-rose-400 data-[selected=true]:to-indigo-400 md:w-24"
              key={v.value}
            >
              <button
                data-selected={value === v.value}
                className="mx-auto my-auto flex h-[calc(100%-2px)] w-[calc(100%-2px)] flex-col items-center justify-center gap-2 rounded-lg bg-gradient-to-r from-rose-50 to-indigo-50 p-2 hover:from-rose-100 hover:to-indigo-100 active:from-rose-100 active:to-indigo-100 active:ring-2 active:ring-sky-300 data-[selected=true]:from-rose-200 data-[selected=true]:to-indigo-200"
                onClick={() => onClick(v.value)}
                data-small={options.length > 5}
                disabled={readonly}
              >
                <div
                  data-optionRenderType={optionRenderType}
                  className="text-center font-sans text-gray-900 data-[optionRenderType=Emoji]:text-2xl data-[optionRenderType=Star]:text-xl"
                >
                  {renderValue(v, optionRenderType, options)}
                </div>
              </button>
            </div>
          ))}
        </div>
        {!hideLabels && (
          <div className="flex items-start justify-between self-stretch">
            {labels.map((label, index) => (
              <div
                key={index}
                className="text-center font-sans text-xs text-gray-500"
              >
                {label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
