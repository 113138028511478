import { gql } from '../__generated__/gql';

export const GET_PROJECT = gql(/* GraphQL */ `
  query GetProject($id: UUID!) {
    project(id: $id) {
      id
      title
      lastResponseDate
      published
      redirectUrl
      thankyouText
      introText
      learningGoal
      totalGrowthSignals
      totalInsights
      totalRiskSignals
      totalUnreadInsights
      avgMessageDepth
      questions {
        id
        type
        text
        required
        aiInsights
        assets {
          id
          name
          url
          description
          createdAt
          updatedAt
        }
        aiConvoDepth
        multipleSelect
        optionRenderType
        randomize
        options {
          id
          label
          value
          assets {
            id
            name
            url
            description
            createdAt
            updatedAt
          }
        }
      }
      tags {
        id
        name
        color
      }
      enableContactInfoCollection
      askContactInformation
    }
  }
`);

export const GET_PROJECT_LIGHTWEIGHT = gql(/* GraphQL */ `
  query GetProjectLightweight(
    $id: UUID!
    $totalInsightsFilters: TotalInsightsFilterInput
    $positiveSentimentFilters: TotalInsightsFilterInput
    $negativeSentimentFilters: TotalInsightsFilterInput
    $neutralSentimentFilters: TotalInsightsFilterInput
  ) {
    project(id: $id) {
      id
      title
      type
      aiLearntText
      learningGoal
      projectThemes {
        id
        name
        sentiment
        totalInsights
        counterTheme {
          id
          name
          sentiment
          totalInsights
        }
      }
      questions {
        id
        text
        type
        options {
          label
          value
        }
      }
      totalInsights: totalInsights(filter: $totalInsightsFilters)
      positiveSentimentInsights: totalInsights(filter: $positiveSentimentFilters)
      negativeSentimentInsights: totalInsights(filter: $negativeSentimentFilters)
      neutralSentimentInsights: totalInsights(filter: $neutralSentimentFilters)
    }
  }
`);

export const GET_PROJECT_PREVIEW = gql(/* GraphQL */ `
  query getProjectPreview($id: UUID!) {
    project(id: $id) {
      id
      published
      questions {
        aiConvoDepth
        aiInsights
        assets {
          id
          name
          url
          description
          createdAt
          updatedAt
        }
        id
        multipleSelect
        optionRenderType
        options {
          id
          label
          value
          assets {
            id
            name
            url
            description
            createdAt
            updatedAt
          }
        }
        randomize
        required
        text
        type
      }
      redirectUrl
      introText
      title
      type
    }

    orgSettings {
      avatarUrl
      logoUrl
      brandColor
      hideTheysaidLogo
    }
  }
`);
