import { useAuth } from '@workos-inc/authkit-react';
import { useEffect } from 'react';

import Redirecting from './auth.redirecting';
import { useNavigate } from 'react-router-dom';

export default function SignOutPage() {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    signOut();
    if (
      ['evo.dev.theysaid.io', 'localhost'].includes(window.location.hostname)
    ) {
      navigate('/login');
    } else {
      window.location.href = 'https://theysaid.io';
    }
  }, [signOut, navigate]);

  return <Redirecting />;
}
